/* eslint-disable no-unused-vars */
import { DataBaseList } from '@/service/dataBaseService/DatabaseService';
export default {
  data() {
    return {
      serach: '',
      fields: [
        {
          key: 'actions',
          label: 'Ações',
          sortable: false,
        },
        {
          key: 'workspace_title',
          label: 'Nome',
          sortable: true,
        },
        {
          key: 'email',
          label: 'Email',
          sortable: true,
        },
        {
          key: 'document',
          label: 'CPF/CNPJ',
          sortable: true,
        },
        {
          key: 'phone',
          label: 'Telefone',
          sortable: true,
        },
      ],
      items: [],
      workspaceList: false,
    };
  },
  created() {
    this.getAllWorkspaces();
  },
  methods: {
    async getAllWorkspaces() {
      this.items = [];
      const params = { inactive: false };

      try {
        const resp = await this.$store.dispatch('getAllWorkspaces', params);
        if (resp) {
          this.items = await Promise.all(
            resp.map(async (item) => {
              const num_doc = async (cpfOrCnpj) => {
                if (cpfOrCnpj) {
                  if (cpfOrCnpj.length == 11) {
                    return await this.maskCpf(item.document);
                  } else if (cpfOrCnpj.length > 11 && cpfOrCnpj.length <= 14) {
                    return await this.mascaraCnpj(cpfOrCnpj);
                  }
                }
              };

              const row = {
                id: item.id,
                phone: await item.phone,
                workspace_title: item.workspace_title,
                email: item.email,
                document: await num_doc(item.document),
              };
              return row;
            })
          );
        }
      } catch (err) {
        console.log(err);
      }
    },
    filter() {
      if (this.search.trim() === '') {
        // If the search input is empty, return all items
        return this.items;
      } else {
        // If there's a search term, filter the items
        return this.items.filter((item) => {
          return (
            item.workspace_title
              .toLowerCase()
              .indexOf(this.search.toLowerCase()) > -1
          );
        });
      }
    },

    cleanFilter() {
      this.search = '';
    },
    async getInactivesWorkspaces() {
      this.items = [];
      try {
        const resp = await this.$store.dispatch('getAllWorkspaces', {
          inactive: true,
        });

        if (resp) {
          this.items = await Promise.all(
            resp.map(async (item) => {
              const num_doc = async (cpfOrCnpj) => {
                if (cpfOrCnpj.length == 11) {
                  return await this.maskCpf(item.document);
                } else if (cpfOrCnpj.length > 11 && cpfOrCnpj.length <= 14) {
                  return await this.mascaraCnpj(cpfOrCnpj);
                }
              };

              const row = {
                id: item.id,
                phone: await item.phone,
                workspace_title: item.workspace_title,
                email: item.email,
                document: await num_doc(item.document),
              };

              return row;
            })
          );
        }
      } catch (err) {
        console.log(err);
      }
    },
  },
};
