<template>
  <b-card title="Áreas de trabalho">
    <b-row class="justify-content-start d-flex">
      <b-tabs content-class="mt-3">
        <b-tab
          title="Ativos"
          @click="(workspaceList = false), getAllWorkspaces()"
          active
        >
        </b-tab>
        <b-tab
          title="Inativos"
          @click="(workspaceList = true), getInactivesWorkspaces()"
        >
        </b-tab>
      </b-tabs>
    </b-row>

    <b-row class="justify-content-between d-flex">
      <b-col>
        <b-row class="ml-2">
          <b-form-group>
            <b-form-input
              type="text"
              v-model="search"
              placeholder="Buscar item"
            ></b-form-input>
          </b-form-group>
        </b-row>
      </b-col>

      <b-col class="d-flex justify-content-end">
        <b-row>
          <b-button
            class="mb-4 mr-2"
            variant="primary"
            @click="$router.push({ name: 'new-workspace' })"
          >
            Nova área de trabalho +
          </b-button>
        </b-row>
      </b-col>
    </b-row>

    <b-table responsive="sm" small :items="filter()" :fields="fields">
      <template v-slot:cell(actions)="{ item }">
        <ActionsWorkspaces :item="item" />
      </template>
    </b-table>
  </b-card>
</template>

<script>
import {
  BCard,
  BTabs,
  BTab,
  BTable,
  BButton,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
} from 'bootstrap-vue';
import ActionsWorkspaces from './components/ActionsWorkspaces';
import WorkspaceMixin from './ListWorkspaces.mixin';

export default {
  mixins: [WorkspaceMixin],
  components: {
    BCard,
    BTabs,
    BTab,
    BTable,
    BButton,
    BRow,
    ActionsWorkspaces,
    BCol,
    BFormInput,
    BFormGroup,
  },
  data() {
    return {
      search: '',
    };
  },
};
</script>

<style></style>
