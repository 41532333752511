<template>
  <div>
    <feather-icon
      v-b-tooltip.hover.lefttop.v-primary
      title="Editar área de trabalho"
      icon="EditIcon"
      size="15"
      @click="$router.push(`/update-workspace/${item.id}`)"
    />
    <feather-icon
      v-b-tooltip.hover.lefttop.v-primary
      title="Vincular usuários"
      icon="UsersIcon"
      size="15"
      class="ml-1"
      @click="$router.push(`/workspaces-users/${item.id}`)"
    />
  </div>
</template>

<script>
import { VBTooltip } from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
export default {
  name: 'actions-workspace',
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    item: { type: Object, default: () => {} },
  },
};
</script>
